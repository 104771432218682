.home .footer {
    position: relative;
    z-index: 1;
    margin-top: 0;
    
    &::before {
        content: none;
    }
}

.footer {
    position: relative;
    z-index: 0;
    margin-top: 160px;
    padding: 80px 0 25px;
    background-color: $color-main;

    &:before {
        content: "";
        position: absolute;
        height: 250px;
        top: -250px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-image: url($urlShort + '/css/courbe_footer_css.svg');
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
        pointer-events: none;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 0;
        width: 400px;
        height: 400px;
        top: 15px;
        right: calc(50% + 475px);
        background: url($urlShort + '/home/bg/fleur_degrade.svg');
        pointer-events: none;
    }

    &__infos {
        z-index: 1;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0;
        color: $color-white;

        &__title {
            color: $color-white;
            font-size: 1.375rem;
            line-height: 1.563rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            margin: 0 0 15px;
        }

        &__text {
            font-size: $font-size--text;
            line-height: 1.25rem;
        }

        &__coordonnees {
            width: 335px;
        }

        &__horaires {
            width: 385px;
        }

        &__logo {
            width: 230px;
            height: 138px;

            & svg {
                width: 230px;
                height: 138px;
                fill: $color-white;
            }
        }
        
        .button {
            width: 270px;
            margin: 55px 0 0 0;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &:hover, &:focus {
                background-color: $color-white;
                border-color: $color-white;
                color: $color-text;

                & svg {
                    fill: $color-text;
                }
            }
        }
    }

    &__menu {
        padding: 50px 0 0;

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            height: 80px;
        }

        li {
            position: relative;
            padding: 15px 0;

            a {
                color: $color-white;
                
                &:hover {
                        color: $color-second;
                }
            } 

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: -35px;
                width: 6px;
                height: 6px;
                background: $color-white;
                border-radius: $border-radius--round;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background :transparent;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        padding: 80px 0 35px;

        &:after {
            right: calc(50% + 325px);
        }

        &__infos {

            &__logo {
                width: 180px;
                height: 110px;
                
                svg {
                    display: inline-block;
                    width: 180px;
                    height: 110px;
                }
            }

            &__horaires {
                width: 340px;
            }

            &__coordonnees {
                width: 300px;
            }

            &__title {
                font-size: 1.25rem;
            }

            &__text {
                font-size: $font-size--text-small;
            }

            & .button {
                width: 260px;
                margin: 40px 0 0 0;
            }
        }

        &__menu {
            li {
                &:before {
                    right: -28px;
                }
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {

        &:after {
            right: calc(50% + 205px);
        }

        &__infos {
            position: relative;
            align-items: center;
            flex-direction: column;
            padding-bottom: 80px;

            &__logo {
                order: 0;
                margin-bottom: 30px;
            }

            &__coordonnees {
                margin-bottom: 30px;

                & .button {
                    position: absolute;
                    right: calc(50% + 10px);
                    bottom: 0;
                }
            }

            &__horaires {
                & .button {
                    position: absolute;
                    left: calc(50% + 10px);
                    bottom: 0;
                }
            }

            &__coordonnees, &__horaires {
                text-align: center;
                order: 1;
            }
        }

        &__menu {

            ul {
                justify-content: center;
                padding: 0 25px
            }

            li {
                padding: 15px 50px 15px 0;

                &:nth-child(3), &:nth-child(7) {
                    padding-right: 0;

                    &:before {
                        content: none;
                    }
                }

                &:before {
                    right: 20px;
                }
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {

        &:after {
            right: calc(50% + 15px);
        }

        &__infos {
            padding-bottom: 150px;
            
            &__coordonnees {
                .button {
                    bottom: 70px;
                }
            }

            &__coordonnees, &__horaires {
                .button {
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                }
            }
        }

        &__container {
            max-width: 320px;
        }

        &__menu {
            ul {
                height: unset;
                padding: 0;

                li {
                    padding: 15px 75px 15px 0;
                    
                    &:nth-child(2), &:nth-child(5) {
                        padding-right: 0;

                        &:before {
                            content: none;
                        }
                    }

                    &:before {
                        right: 35px;
                    }
                }
            }
        }
    }

}