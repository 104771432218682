.search-container {
    display: flex;
    position: relative;
    align-items: center;

    .searchInput {
        padding: 24px 40px 24px 24px;
        border: 1px solid $color-gray;
        background-color: $color-white;
        border-radius: 50px;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &::placeholder {
            color: $color-text;
        }
    }

    .search-svg {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 20px;
        bottom: 0;
        margin: auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: $border-radius--round;
        background: $color-bg--transparent;
        transition: all $duration ease-in-out;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-dark;
            transition: all $duration ease-in-out;
        }

        &:hover {
            background: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border-top: 1px solid $color-gray;
    box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

    li {
        position: relative;
        background: $color-white;
        border-bottom: 1px solid $color-bg--neutral;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-gray;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-gray;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom:0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 20px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 25;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-bg--neutral;
    transition: all 0.65s;

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            
            &:before {
                content: "";
                position: absolute;
                z-index: 0;
                width: 400px;
                height: 400px;
                top: -75px;
                right: calc(50% + 285px);
                background: url($urlShort + '/home/bg/fleur_degrade-blue.svg');
            }
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-dark;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            z-index: 1;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            .searchInput {
                background: $color-white;
                border-color: $color-white;
            }
        }

        .searchResult {
            padding-top: 35px;
            margin-top: -34px;
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        border-color: $color-main;
        background: $color-main;
        border-radius: 50%;
        transition: all $duration;
        
        svg {
            width: 25px;
            height: 25px;
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            background: $color-bg--neutral;
            svg {
                fill: $color-main;
            }
        }
    }
}


//======================================================================================================
// Search Page
//======================================================================================================
// .filter {
//     .search-container {
//         .search-svg {
//             background: $color-gray;
//             border-top-right-radius: $border-radius;
//             border-bottom-right-radius: $border-radius;
//             transition: all $duration;

//             &:hover {
//                 background: $color-main;
//             }

//             svg {
//                 fill: $color-white;
//             }
//         }
//     }
// }


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        font-size: 2.5em;
    }

}
