.front-page {
    .home {
        &__title {
            font-size: 4.375rem;
            line-height: 4.375rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            padding: 0;
            margin: 0;

            &:before {
                content: none;
            }
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        padding-top: 150px;

        &:before {
            content: "";
            position: absolute;
            top: -85px;
            right: calc(50% + 320px);
            width: 400px;
            height: 400px;
            background-image: url($urlShort + '/home/bg/fleur_border.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 260px;
        right: calc(50% + 365px);
        height: 400px;
        width: 400px;
        background-image: url($urlShort + '/home/bg/fleur_border.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    // Barre de recherche
    .home-search {
        &__container {
            position: absolute;
            z-index: 5;
            width: 570px;
            left: 0;
            right: 0;
            top: 295px;
            margin: 0 auto;

            .search-popup__content {
                .search-container {
                    z-index: 1;

                    .searchInput {
                        height: 50px;
                        border: 1px solid rgba($color-white, .7);
                        border-radius: 50px;
                        background-color: rgba($color-white, .7);
                        transition: all $duration ease-in-out;

                        &::placeholder {
                            color: $color-text;
                        }

                        &:hover, &:focus {
                            background-color: rgba($color-white, 1);
                        }
                    }

                    .search-svg {
                        svg {
                            width: 40px;
                            height: 40px;
                            fill: $color-text;
                        } 

                        &:hover, &:focus {
                            svg {
                                fill: $color-white;
                            }
                        }
                    }
                }

                .searchResult {
                    padding: 51px 0 0;
                    margin-top: -50px;
                    border-radius: 30px 30px 0 0;                
                }
            }
        }
    }
    // [END] Barre de recherche


    // Zone libre
    .access__admin {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute!important;
        z-index: 4;
        width: 570px;
        top: 365px;
        left: 0;
        right: 0;
        margin: 0 auto;

        &--link {
            display: inline-flex;
            align-items: center;
            padding: 0 25px;
            height: 40px;
            color: $color-white;
            border-radius: 50px;
            background-color: $color-main;
            text-transform: uppercase;
            font-size: $font-size--text-small;
            margin-right: 15px;
            margin-bottom: 15px;
            transition: all $duration ease-in-out;

            svg {
                width: 20px;
                height: 20px;
                fill: $color-white;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-text;
                background-color: $color-second;

                svg {
                    fill: $color-text;
                }
            }
        }
    }
    // [END] Zone libre

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none;

            .swiper-pagination-bullet {
                pointer-events: auto;
            }
        }
    }

    .slideshow {
        height: 700px;
        width: 100%;
        max-width: 2000px;

        .shape-container {
            position: relative;
            left: calc(50% - (2000px/2));
            width: 2000px;
            height: 700px;

            &__image, &__video {
                display: block;
                width: 2000px;
                height: 700;
            }

            &--desktop {
                display: block;
            }

            &--mobile {
                display: none;
            }
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            &--img, &--video {
                position: relative;
                clip-path: url(#shapeView0);
                top: 0;
                height: 700px;

                & rect {
                    position: absolute;
                    z-index: 1;
                    width: 2000px;
                    height: 700px;
                    left: calc(50% - (2000px/2));
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    background: linear-gradient(180deg, rgba($color-dark, 0.65) 0%, rgba(5, 8, 50, 0.045) 100%);
                }
            }

            &--video {
                clip-path: url(#shapeViewVideo)!important;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            right: 30px;
            bottom: 50px;
            width: 500px;
            height: auto;
            padding: 20px;
            background: $color-second--rgba;
            color: $color-white;
        }

        &__title {
            margin-bottom: 15px;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            right: 30px;
            top: 285px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin: 0 0 10px;
                transition: .25s;
                border: 1px solid $color-white;
                background-color: $color-white;
                border-radius: 5px;
                opacity: 1;
    
                &:hover {
                    background-color: $color-bg--transparent;
                }
    
                &-active {
                    background-color: $color-bg--transparent;
                }
            }
        }
    
    }
}


//======================================================================================================
// Posts
//======================================================================================================
.posts {
    &__section {
        position: relative;
        z-index: 10;
        padding: 25px 0 125px;
    }

    &__container {
        position: relative;
    }

    &__heading {
        margin-bottom: 30px;
    }

    &__title.home__title {
        display: inline-block;
    }

    &__btn {
        position: absolute;
        z-index: 5;
        left: calc(50% + 115px);
        bottom: 50px;
        width: 400px;
        height: 50px;
        display: flex;
        margin: 0;
        color: $color-text;
        border-color: $color-second;
        transition: all $duration ease-in-out;
        
        &:before {
            background-color: $color-white;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;

            &:before {
                background-color: $color-second;
            }
        }
    }

    &__item {
        display: inline-block;
        width: 370px;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }

        &__taxo {
            position: relative;
            font-style: italic;
            font-size: $font-size--text-small;
            line-height: 1.25rem;
            font-weight: $font-weight-light;
            font-family: $font-family--heading;
            text-transform: uppercase;
            transition: all $duration ease-in-out;

            &:before {
                content: "";
                position: absolute;
                height: 1px;
                background-color: $color-text;
                transition: all $duration ease-in-out;
            }
        }

        &--0 {
            display: flex;
            position: relative;
            margin-right: 0;
            margin-bottom: 30px;
            width: 100%;

            &:hover, &:focus {
                .posts__item__taxo {
                    &:before {
                        width: 60px;
                    }
                }
            }

            .posts {
                &__item {
                    &__content {
                        width: 500px;
                        height: auto;
                        max-height: 350px;
                        padding: 40px 70px 100px 30px;
                    }

                    &__container-img {
                        height: 350px;
                        margin: 0;
                        border-radius: $border-radius;

                        &:before {
                            content: unset;
                        }

                        & img {
                            height: 100%;
                            object-fit: cover;
                            border-radius: $border-radius;
                        }

                        &--notimg {
                            height: 350px;
                            width: 670px;
                        }
                    }

                    &__title {
                        font-size: 1.875rem;
                        line-height: 2.188rem;
                        padding: 0;
                        margin: 0 0 10px;

                        &:before {
                            content: none;
                        }
                    }

                    &__intro {
                        font-size: 1.125rem;
                        line-height: 1.563rem;
                    }

                    &__taxo {
                        margin-bottom: 20px;

                        &:before {
                            left: 0;
                            bottom: -11px;
                            width: 40px;
                            transition: all $duration ease-in-out;
                        }
                    }
                }
            }

            &:hover {
                .posts {
                    &__item {
                        &__title {
                            &:before {
                                width: 120px;
                            }
                        }
                    }
                }
            }
        }

        &__container-img {
            position: relative;
            height: 220px;
            margin-bottom: 15px;
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-gray;
            background-position: center;
            border-radius: $border-radius;
            overflow: hidden;

            & img {
                height: 100%;
                width: 100%;
                object-position: top;
                object-fit: cover;
                border-radius: $border-radius;
                transform: scale(1);
                transition: all $duration ease-in-out;
            }
        }
    
        &__title {
            font-size: 1.25rem;
            line-height: 1.563rem;
            margin: 0 0 10px 0;
    
            &:before {
                content: none;
            }
        }

        &:hover, &:focus {
            
            .posts__item__container-img {
                & img {
                    transform: scale(1.05);
                }
            }
        }
    }

    &__template {
        position: relative;
        height: 320px;
        margin-bottom: -320px;
    }

    &__container {
        position: relative;
        display: flex;
    }

    &__list {
        &-small {
            position: relative;
            margin: 0;
            height: 320px;
            overflow: visible;

            .posts {
                &__item {

                    &__content {
                        margin-top: 20px;
                    }

                    &__title {
                        font-size: 1.375rem;
                        line-height: 1.563rem;
                        font-weight: $font-weight-semibold;
                    }

                    &__taxo {
                        padding-left: 35px;
                        margin-bottom: 10px;
                        transition: all $duration ease-in-out;
                        
                        &:before {
                            width: 25px;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            transition: all $duration ease-in-out;
                        }
                    }

                    &:hover, &:focus  {
                        .posts__item__taxo {
                            padding-left: 50px;

                            &:before {
                                width: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Events
//======================================================================================================
.events {

    &__section {
        position: relative;
        z-index: 0;
        margin-bottom: 260px;

        &:before, &::after {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            width: 100%;
            height: 1140px;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &:before {
            top: 160px;
            background-image: url($urlShort + "home/bg/courbe_agenda_haut.svg");
        }

        &:after {
            top: 630px;
            background-image: url($urlShort + "home/bg/courbe_agenda_bas.svg");
        }
    }

    &__container {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            width: 400px;
            height: 400px;
            top: 340px;
            left: calc(50% + 250px);
            background: url($urlShort + '/home/bg/fleur_degrade.svg');
        }
    }

    &__heading {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    &__navigation {
        position: relative;
        width: 90px;
        height: 40px;
        right: 30px;

        .swiper-button {
            width: 40px;
            height: 40px;
            border: 0;
            padding: 0;
            margin: 0;
            top: 0;
            background-color: $color-second;
            transition: background-color $duration ease-in-out;

            &:before, &:after {
                content: none;
            }

            &-prev {
                left: 0;
            }

            &-next {
                right: 0;
            }

            & svg {
                fill: $color-text;
                width: 40px;
                height: 40px;
            }

            &.swiper-button-disabled {
                opacity: 1;
                background-color: $color-light;
            }

            &:hover, &:focus {
                background-color: $color-gray;
            }
        }
    }

    &__list {
        overflow: hidden;

        .swiper-container {
            height: 340px;
            overflow: visible;

            .swiper-slide {
                max-width: 360px;
                width: 360px;
                height: 340px;
            }
        }

        .events__container {
            &:before {
                content: none;
            }
        }
    }

    &__item {
        display: inline-block;
        position: relative;
        z-index: 0;
        border-radius: $border-radius;
        overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background: linear-gradient(360deg, rgba($color-black, 0.6) 0%, rgba($color-black, 0.36) 43.23%, rgba($color-black, 0) 100%), linear-gradient(0deg, rgba($color-black, 0.4), rgba($color-black, 0.4));
        }

        &__container-img {
            width: 360px;
            height: 340px;
            border-radius: $border-radius;
            overflow: hidden;

            img {
                width: 360px;
                height: 340px;
                border-radius: $border-radius;
                transform: scale(1);
                object-fit: cover;
                transition: all $duration ease-in-out;
            }

            &--notimg {
                background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-gray;
                background-position: center;
            }
        }

        &:hover, &:focus {
            .events__item__container-img {
                img {
                    transform: scale(1.05);
                }
            }

            .events__item__location svg {
                transform: rotate(90deg);
            }
        }

        &__infos {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            padding: 40px 70px 40px 30px;
            color: $color-white;
        }

        &__title {
            height: 100px;
            font-size: 1.375rem;
            line-height: 1.563rem;
            font-weight: $font-weight-semibold;
            color: $color-white;
            margin: 40px 0;
        }

        &__location {
            font-size: $font-size--text;
            line-height: 1.25rem;
            font-style: italic;

            svg {
                width: 15px;
                height: 15px;
                fill: $color-second;
                margin-right: 10px;
                transform: rotate(0);
                transition: all $duration ease-in-out;
            }
        }

        &__date {
            display: flex;
            font-family: $font-family--heading;

            > p {
                display: flex;
                flex-direction: column;

                &:last-child {
                    position: relative;
                    z-index: 0;
                    margin-left: 40px;

                    &:before {
                        content: "";
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: -25px;
                        bottom: 0;
                        margin: auto 0;
                        width: 10px;
                        height: 10px;
                        background-color: $color-white;
                        border-radius: $border-radius--round;
                    }
                }
            }

            &--same {
                p {
                    margin-left: unset!important;

                    &:before {
                        content: none!important;
                    }
                }
            }
        }

        &__day {
            font-size: 2.5rem;
            line-height: 2.5rem;
            font-weight: $font-weight-bold;
        }

        &__month {
            font-size: 0.938rem;
            line-height: 1.25rem;
            text-transform: uppercase;
        }
    }

    &__template {
        margin-top: 40px;

        &--btn {
            width: 270px;
            margin: 0 30px 0 0;

            &:first-child {
                border-color: $color-white;
                background-color: $color-white;
                color: $color-text;

                &:hover, &:focus {
                    border-color: $color-second;
                    background-color: $color-second;
                    color: $color-text;
                }
            }
            
            &:last-child {
                margin: 0;
                &:hover, &:focus {
                    color: $color-text;
                    background-color: $color-white;
                    border-color: $color-white;
                }
            }
        }
    }
}


//======================================================================================================
// Social Wall
//======================================================================================================
.sw {
    &__section {
        position: relative;
        z-index: 2;
    }

    &__heading {
        display: flex;
        align-items: center;
    }

    &__title.home__title {
        margin-right: 40px;
    }

    &__buttons {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 175px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 1px;
            width: 150px;
            margin: auto 0;
            background-color: $color-main;
        }
    }

    &__btn {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-right: 15px;
        background-color: $color-bg--transparent;
        border-radius: $border-radius--round;
        transition: all $duration ease-in-out;

        svg {
            width: 40px;
            height: 40px;
            fill: $color-main;
        }

        &:hover, &:focus {
            background-color: $color-light;
        }
    }

    &__container {
        &--sw {
            #ff-stream-1 {
                width: 1170px;
                min-height: 270px!important;
                max-height: 270px!important;
                margin-top: 40px;
                padding: 0;
                background-color: $color-bg--transparent;

                .ff-header, .ff-loadmore-wrapper {
                    display: none!important;
                }

                .ff-stream-wrapper {
                    width: 1230px;
                    max-height: 270px;
                    padding: 0;
                    margin: 0 0 0 -30px;

                    .ff-item {
                        width: 570px;
                        height: 270px;
                        padding: 0;
                        background-color: $color-bg--transparent;
                        border-radius: 20px!important;
                        box-shadow: unset!important;

                        &:hover, &:focus, &:focus-within {
                            box-shadow: $shadow!important;
                        }

                        .picture-item__inner {
                            position: relative;
                            color: $color-text!important;
                            box-shadow: unset!important;
                            width: 570px!important;
                            height: 270px!important;
                            padding: 20px!important;
                            border-radius: 20px!important;
                            background-color: $color-light!important;

                            &:before {
                                content: "";
                                position: absolute;
                                top: unset;
                                left: unset;
                                right: 220px;
                                bottom: 65px;
                                width: 40px;
                                height: 1px;
                                background-color: $color-main!important;
                            }

                            .ff-item-bar {
                                position: absolute;
                                width: 240px;
                                right: 20px;
                                bottom: 20px;
                                border: 0!important;
                                padding: 20px 0 0 0;
                                color: $color-text!important;

                                & a {
                                    color: $color-text!important;
                                }

                                .ff-share-popup {
                                    background: $color-white!important;

                                    &:after {
                                        background: $color-white!important;
                                    }
                                }
                            }
                        }

                        .ff-item-cont {
                            display: flex;
                            flex-wrap: wrap;
                            height: 230px;

                            .ff-img-holder {
                                width: 270px;
                                height: 230px;
                                margin-right: 20px;
                                border-radius: 5px;

                                img {
                                    min-height: 230px!important;
                                    border-radius: 5px;
                                    object-fit: cover!important;
                                }
                            }

                            .ff-content {
                                width: 210px;
                                height: 155px!important;
                                padding-top: 15px;
                                margin: 0;

                                a {
                                    color: $color-text!important;
                                }
                            }

                            .ff-item-meta {
                                display: none;
                            }

                            .ff-label-wrapper {
                                .ff-icon {
                                    width: 30px;
                                    height: 30px;
                                    border: 0!important;
                                    border-radius: $border-radius--round!important;
                                    background-color: $color-main;

                                    &:before {
                                        content: none!important;
                                    }

                                    .ff-icon-inner {
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        margin: auto;
                                        font-size: 0.75rem!important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Newsletter
//======================================================================================================
.newsletter {
    &__section {
        position: relative;
        z-index: 1;
        width: 300px;
        height: 320px;
        background-color: $color-light;
        padding: 45px 30px 40px;
        border-radius: 10px;
    }

    &__container {
        svg {
            position: absolute;
            top: -20px;
            right: -30px;
            fill: $color-main;
            height: 90px;
            width: 90px;
        }
    }

    &__title.home__title {
        position: relative;
        font-size: 1.875rem;
        line-height: 2.188rem;
        margin-bottom: 40px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -21px;
            height: 1px;
            width: 40px;
            background-color: $color-main;
        }
    }

    &__text {
        font-size: $font-size--text;
        line-height: 1.25rem;

        &--span {
            font-weight: $font-weight-bold;
        }
    }

    &__link {
        width: 100%;
        margin: 30px 0 0;
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {
    &__section {
        position: relative;
        z-index: 1;
        width: 730px;
        height: 390px;
        margin-top: -15px;
        margin-right: 30px;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 40px;
    }

    &__left {
        width: 390px;
        height: 350px;
        overflow: hidden;
    }

    &__right {
        width: 300px;
        height: 250px;
    }

    .swiper-container {

        &__images {
            width: 230px;
            height: 350px;
            padding: 15px 0;
            overflow: visible;

            .swiper-slide {
                position: relative;
                border-radius: 10px;
                overflow: hidden;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    background-color: rgba($color-main, .7);
                    transition: all $duration ease-in-out;
                    pointer-events: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }

                &.swiper-slide-active {
                    border-radius: 5px;
                    box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);

                    img {
                        border-radius: 5px;
                    }

                    &:before {
                        background-color: rgba($color-main, 0);
                    }
                }
            }
        }

        &__content {
            margin-top: 25px;
            height: 155px;

            .swiper-slide {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background-color: $color-white;
            }
             
            .kiosque {

                &__infos {
                    position: relative;
                    padding-left: 35px;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: uppercase;
                    font-style: italic;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto 0;
                        width: 25px;
                        height: 1px;
                        background-color: $color-dark;
                    }
                }

                &__title {
                    font-size: 1.375rem;
                    line-height: 1.563rem;
                    font-weight: $font-weight-semibold;
                    margin: 0 0 10px;
                }
            }
        }
    }

    &__buttons {
        display: flex;
    }

    &__button {
        width: 50px;
        height: 50px;
        background-color: $color-second;
        border-radius: $border-radius--round;
        margin-right: 15px;
        transition: all $duration ease-in-out;

        & svg {
            width: 50px;
            height: 50px;
        }

        &:hover, &:focus, &:focus-within {
            background-color: $color-gray;
        }

        &:last-child {
            margin-right: 0;
        }
    }
    
    &__navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 90px;
        height: 40px;
        left: 150px;

        & .kiosque {

            &__prev, &__next {
                width: 40px;
                height: 40px;
                border: 0;
                padding: 0;
                margin: 0;
                top: 0;
                background-color: $color-gray;
                border-radius: $border-radius--round;
                cursor: pointer;
                transition: background-color $duration ease-in-out;

                & svg {
                    fill: $color-text;
                    width: 40px;
                    height: 40px;
                }

                &:hover, &:focus, &:focus-within {
                    background-color: $color-second;
                }
            }

            &:before, &:after {
                content: none;
            }

            &__prev {
                left: 0;
            }

            &__next {
                right: 0;
            }

            &:hover, &:focus {
                background-color: $color-second;
            }
        }
    }
}


//======================================================================================================
// Map interactive
//======================================================================================================
.territoire {
    &__section {
        position: relative;
        z-index: 0;
        padding-top: 245px;
        padding-bottom: 100px;
        background: linear-gradient(180deg, rgba($color-dark, 0) 0%, rgba($color-dark, 0.2) 26.1%, rgba($color-dark, 0.4) 47.72%, rgba($color-main, 0.9) 79.17%, $color-main 100%), url($urlShort + "home/territoire/img_territoire.jpg");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            top: -990px;
            width: 100%;
            height: 1160px;
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url($urlShort + "home/bg/courbe_agenda_bas.svg");
        }

        &::after {
            content: "";
            position: absolute;
            top: 570px;
            left: calc(50% + 470px);
            width: 515px;
            height: 515px;
            background-image: url($urlShort + "home/territoire/carte_situation.svg");
            background-repeat: no-repeat;
            background-position: top;
            background-size: cover;
            pointer-events: none;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__heading {
        display: flex;
    }

    &__title.home__title {
        color: $color-white;
        margin-bottom: 30px;
    }

    &__dropdown {
        display: none;
    }

    &__list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 440px;
        width: 530px;
        gap: 10px 20px;
    }

    &__item {
        display: flex;
        align-items: center;
        width: 250px;
        height: 20px;

        a {
            position: relative;
            display: inline-block;
            width: 100%;
            font-size: 0.938rem;
            line-height: 1.25rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            padding-left: 20px;
            color: $color-white;

            & svg {
                position: absolute;
                top: 0;
                left: -5px;
                bottom: 0;
                margin: auto 0;
                width: 16px;
                height: 16px;
                fill: $color-second;
                opacity: 0;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus, &:focus-within, &.ville__hover {
                & svg {
                    opacity: 1;
                }

                &:before {
                    opacity: 0;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto 0;
                width: 6px;
                height: 6px;
                background-color: $color-white;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;
            }
        }
    }

    &__map {
        margin-top: -110px;
        width: 585px;
        height: 540px;

        svg {
            display: inline-block;
            width: 585px;
            height: 540px;
            filter: drop-shadow(0px 0px 20px rgba(2, 2, 11, 0.2));

            a {
                path {
                    transition: all $duration ease-in-out;
                
                    &.map__hover {
                        fill: $color-second;
                        fill-opacity: 1;
                    }
                }

                &:hover {
                    path {
                        fill: $color-second;
                        fill-opacity: 1;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {
        .home {

            &__title {
                font-size: 3.125rem;
                line-height: 3.125rem;
            }
        }

        &__container {
            &:before {
                right: calc(50% + 285px);
                top: -115px;
            }
        }
    }

    // Alu
    .home-slideshow {
        
        &:before {
            right: calc(50% + 230px);
            top: 225px;
        }

        .slideshow {
            height: 580px;

            .swiper-wrapper {
                &:before {
                    left: calc(50% - (1657px/2));
                    width: 1657px;
                    height: 580px;
                }
            }

            .shape-container {
                left: calc(50% - (1657px/2));
                width: 1657px;
                height: 580px;

                &__image, &__video {
                    width: 1657px;
                    height: 580px;
                }
            }

            &__pagination {
                top: 255px;
            }
        }

        & .home-search {
            &__container {
                top: 275px;
            }
        }

        & .access__admin {
            top: 345px;
        }
    }

    // Post
    .posts {
        &__item {
            width: 300px;
            margin-right: 20px;

            &__title {
                font-size: 1.125rem
            }
            
            &--0 {
                width: 100%;
                margin-bottom: 20px;

                .posts {
                    &__item {
                        &__container-img {
                            height: 285px;
                            width: 530px;

                            img {
                                width: 100%;
                                height: 285px;
                            }
                        }

                        &__content {
                            width: 380px;
                            max-height: 285px;
                            padding: 10px 0 85px 30px;
                        }

                        &__title {
                            font-size: 1.75rem;
                        }

                        &__intro {
                            font-size: $font-size--text;
                            line-height: 1.25rem;
                        }
                    }
                }
            }

            &__container-img {
                width: 300px;
                height: 180px;

                img {
                    width: 300px;
                   height: 180px;
                }
            }
        }

        &__list-small {
            height: 270px;

            .posts__item {

                &__container-img {
                    margin-bottom: 10px;
                }
                &__content {
                    margin: 0;
                    padding: 0;
                }
            }
        }

        &__btn {
            bottom: 40px;
            width: 365px;
            left: calc(50% + 90px);
        }
    }

    // Events
    .events {

        &__section {

            &:before {
                top: 150px;
            }

            &:after {
                top: 580px;
            }
        }

        &__container {
            &:before {
                top: 290px;
            }
        }

        &__navigation {
            right: 0;
        }

        &__list {
            .swiper-container {
                height: 310px;

                .swiper-slide {
                    height: 310px;
                    max-width: 300px;
                    width: 300px;
                }
            }
        }

        &__item {
            &__container-img {
                width: 300px;
                height: 310px;
            }

            &__infos {
                padding: 30px 20px;
            }

            &__title {
                margin: 35px 0;
            }
        }

        &__template {
            &--btn {
                width: 300px;

                &:first-child {
                    margin: 0 20px 0 0;
                }
            }
        }
    }

    // Social
    .sw {
        &__container {
            &--sw {
                #ff-stream-1 {
                    width: 940px!important;
                    max-height: 240px!important;
                    min-height: 240px!important;

                    .ff-stream-wrapper {
                        width: 980px;
                        margin: 0 0 0 -20px;
                        max-height: 240px;

                        .ff-item {
                            height: 240px!important;

                            .picture-item__inner {
                                height: 240px!important;
                                width: 460px!important;

                                .ff-item-bar {
                                    width: 180px!important;
                                }

                                &:before {
                                    right: 160px;
                                }
                            }

                            .ff-item-cont {
                                height: 200px!important;

                                .ff-img-holder {
                                    width: 220px!important;
                                    height: 200px!important;
                                }

                                .ff-content {
                                    padding-top: 0!important;
                                    width: 160px!important;
                                    height: 140px!important;
                                }
                            }
                        }
                        .ff-label-wrapper {
                            right: -10px!important;
                            top: -10px!important;
                        }
                    }
                }
            }
        }
    }

    // Newsletter
    .newsletter {

        &__section {
            width: 240px;
            height: 290px;    
            padding: 30px 20px 25px;
        }

        &__container {
            svg {
                width: 60px;
                height: 60px;
                right: -23px;
                top: -8px;
            }
        }

        &__title.home__title {
            font-size: 1.875rem;
            line-height: 2.188rem;
        }

        &__text {
            font-size: $font-size--text-small;
            line-height: 1.25rem;
        }
    }

    // Kiosque
    .kiosque {
        &__section {
            height: 345px;
            margin-right: 0;
            margin-top: -10px;
            width: 610px;
        }

        &__left {
            width: 360px;
            height: 310px;
        }

        &__right {
            height: 230px;
            width: 220px;
        }

        .swiper-container {
            &__images {
                height: 310px;
                width: 210px;
                padding: 10px 0;
            }

            &__content {
                .kiosque {
                    &__title {
                        font-size: 1.125rem;
                        line-height: 1.563rem;
                    }
                }
            }
        }

        &__navigation {
            left: 135px;
            bottom: -10px;
        }
    }

    // Territoire
    .territoire {

        &__section {
            &:after {
                left: calc(50% + 360px);
                top: 465px;
            }
        }

        &__list {
            gap: 10px;
            height: 410px;
            width: 460px
        }

        &__item {
            height: 18px;
            width: 220px;

            a {
                font-size: 0.75rem;
                line-height: 1.125rem;
            }
        }

        &__map {
            height: 465px;
            width: 500px;
            margin-top: -110px;

            svg {
                height: 465px;
                width: 500px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        .home {

            &__title {
                font-size: 2.813rem;
                line-height: 3.125rem;
            }
        }

        &__container {
            flex-direction: column;
            padding-top: 60px;

            &:before {
                right: calc(50% + 165px);
                top: 75px;
            }
        }
    }

    // Alu
    .home-slideshow {

        &:before {
            top: 175px;
            right: calc(50% + 200px);
            width: 300px;
            height: 300px;
        }

        .slideshow {
            height: 470px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            .shape-container {
                left: calc(50% - (1343px/2));
                width: 1343px;
                height: 470px;

                &__image, &__video {
                    width: 1343px;
                    height: 470px;
                }

                &--video {
                    height: 0;
                }

                &--desktop {
                }

                &--mobile {
                    display: block;
                    clip-path: url(#shapeViewVideoMobile)!important;
                }
            }

            &__pagination {
                top: 200px;
            }
        }

        & .home-search, & .access__admin {
            display: none;
        }
    }

    // Posts
    .posts {

        &__item {
            &--0 {

                & .posts {
                    &__item {
                        &__container-img {
                            width: 300px;
                            height: 200px;

                            img {
                                width: 300px;
                                height: 200px;
                            }
                        }

                        &__content {
                            width: 320px;
                            padding: 20px 0 20px 20px;
                        }

                        &__title {
                            font-size: 1.375rem;
                            line-height: 1.563rem;
                        }

                        &__intro {
                            font-size: 0.875rem;
                            line-height: 1.25rem;
                        }
                    }
                }
            }

            &--3 {
                display: none;
            }
        }

        &__btn {
            width: 300px;
            left: unset;
            right: 10px;
            bottom: -340px;
        }
    }

    // Events
    .events {

        &__section {
            margin-bottom: 200px;

            &:before {
                top: 180px;
            }

            &:after {
                top: 560px;
            }
        }
        
        &__container {
            &:before {
                top: 270px;
            }
        }

        &__template {
            display: flex;
        }
    }

    // Social Wall
    .sw {

        &__buttons {
            padding-left: 60px;

            &:before {
                width: 50px;
            }
        }

        &__btn {
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }
        }

        &__container {
            &--sw {
                #ff-stream-1 {
                    margin-top: 30px;
                    width: 100%!important;
                    max-height: 320px!important;
                    min-height: 320px!important;

                    .ff-stream-wrapper {
                        max-height: 320px!important;
                        width: 660px!important;

                        .ff-item {
                            height: 320px!important;

                            .picture-item__inner {
                                padding: 15px!important;
                                height: 320px!important;
                                width: 300px!important;

                                &:before {
                                    right: 245px!important;
                                }

                                .ff-item-bar {
                                    width: 270px!important;
                                }
                            }

                            .ff-item-cont {
                                flex-direction: column!important;
                                height: 290px!important;

                                .ff-img-holder {
                                    width: 270px!important;
                                    height: 140px!important;
                                    margin: 0!important;
                                }

                                .ff-content {
                                    padding-top: 10px!important;
                                    height: 80px!important;
                                    width: 270px!important;
                                }
                            }
                        }

                        .ff-label-wrapper {
                            right: -5px!important;
                            top: -5px!important;
                        }
                    }
                }
            }
        }
    }

    // Newsletter
    .newsletter {

        &__section {
            width: 100%;
            height: 155px;
            margin-bottom: 60px;
            padding: 20px;
        }

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            height: 115px;

            & svg {
                right: 10px;
                top: -10px;
            }
        }

        &__title.home__title {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 1.875rem;
            line-height: 2.188rem;

            &:before {
                bottom: -11px;
            }
        }

        &__text {
            font-size: $font-size--text;
            width: 280px;
        }

        &__link {
            width: 200px;
            margin-top: auto;
            margin-left: 100px;
            margin-bottom: 5px;
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            width: 100%;
        }

        &__right {
            width: 230px;
        }
    }

    // Territoire
    .territoire {

        &__section {
            padding-top: 210px;
            padding-bottom: 190px;

            &:before {
                height: 920px;
                top: -820px;
                width: 100%;
                background-size: contain;
            }

            &:after {
                left: calc(50% + 140px);
                top: 150px;
            }
        }

        &__dropdown {
            display: block;
            position: relative;

            &--select {
                height: 50px;
                border-radius: 30px;
                padding: 15px 30px;
                width: 420px;
                appearance: none;
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: $font-weight-bold;
                background-color: $color-white;
                border: 0;

                option {
                    &:hover, &:focus {
                        background-color: $color-main;
                    }
                }
            }

            svg {
                position: absolute;
                width: 20px;
                height: 20px;
                top: 0;
                right: 30px;
                bottom: 0;
                margin: auto 0;
                fill: $color-text;
            }
        }

        &__list {
            display: none;
        }

        &__map {
            display: none;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .front-page {
        &__container {
            &:before {
                right: calc(50% + 5px);
                top: 115px;
            }
        }
    }

    // Alu
    .home-slideshow {

        &:before {
            top: 235px;
            right: calc(50% + 130px);
            width: 200px;
            height: 200px;
        }
        
        .slideshow {
            height: 375px;

            .shape-container {
                left: calc(50% - (1071px/2));
                width: 1071px;
                height: 375px;

                &__image, &__video {
                    width: 1071px;
                    height: 375px;
                }

                &--video {
                    height: 0;
                }
            }

            &__pagination {
                top: 160px;
            }
        }
    }

    // Posts
    .posts {
        &__section {
            padding-bottom: 80px;
        }
        
        &__item {
            flex-direction: column;
            margin-right: 0;
            margin-bottom: 20px;
            width: 100%;

            &--0 {
                margin-bottom: 100px;

                .posts {
                    &__item {
                        &__content {
                            width: 100%;
                            padding: 20px 0 0;
                        }

                        &__container-img {
                            width: 100%;
                            height: auto;

                            img {
                                display: block;
                                height: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            &__container-img {
                width: 100%;
                height: auto;
                
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }

        &__list-small {
            height: unset;

            .posts__container {
                flex-direction: column;
            }
        }

        &__btn {
            bottom: 30px;
            top: unset;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    // Events
    .events {

        &__section {
            margin-bottom: 160px;

            &:before {
                top: 230px;
            }

            &:after {
                top: 620px;
            }
        }
        
        &__container {
            &:before {
                top: 320px;
                left: calc(50% - 5px);
            }

            .swiper-container {
                width: 300px;
                margin-right: auto;
                margin-left: 0;
            }
        }

        &__template {
            position: relative;
            z-index: 1;
            flex-direction: column;

            &--btn {
                &:first-child {
                    margin: 0 0 20px;
                }
            }
        }
    }

    // Social
    .sw {

        &__heading {
            flex-direction: column;
            align-items: flex-start;
        }

        &__title.home__title {
            margin-bottom: 10px;
            margin-right: 0;
        }

        &__container {
            &--sw {
                #ff-stream-1 {
                    max-height: 660px!important;
                    min-height: 660px!important;

                    .ff-stream-wrapper {
                        max-height: 660px !important;
                        width: calc(100% + 40px)!important;

                        .ff-item {
                            width: 100% !important;

                            .ff-item-cont {
                                .ff-img-holder {
                                    width: 100%!important;

                                    img {
                                        object-fit: cover!important;
                                        min-height: unset!important;
                                        height: 100%!important;
                                    }
                                }

                                .ff-content {
                                    width: 100%!important;
                                }
                            }

                            .picture-item__inner {
                                width: 100%!important;

                                &:before {
                                    right: unset!important;
                                    left: 15px!important;
                                }

                                .ff-item-bar {
                                    width: calc(100% - 30px)!important;
                                    right: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Newsletter
    .newsletter {

        &__section {
            height: auto;
            margin-bottom: 140px;
        }

        &__container {
            height: auto;
        }

        &__text {
            width: auto;
        }

        &__link {
            margin-top: 20px;
            margin-left: unset;
            width: 100%;
        }
    }

    // Kiosque
    .kiosque {
        &__section {
            position: relative;
            height: 650px;
        }

        &__container {
            flex-direction: column;
            align-items: flex-start;
            max-width: 100%;
        }

        &__left {
            height: 415px;
            width: 300px;
        }

        &__right {
            width: 100%;
        }

        .swiper-container {
            &__images {
                width: 300px;
                height: 415px;
                padding: 0;

                .swiper-slide {
                    opacity: 0;

                    &.swiper-slide-active {
                        opacity: 1;
                    }
                }
            }

            &__content {
                margin-top: 80px;

                .swiper-slide {
                    justify-content: flex-start;
                }
            }
        }

        &__item {
            &__heading {
                position: absolute;
                top: -80px;
            }
        }

        &__navigation {
            bottom: unset;
            left: 0;
            top: 435px;
        }

        &__buttons {
            margin-top: 20px;
        }
    }

    // Territoire
    .territoire {

        &__section {
            padding-top: 125px;
            padding-bottom: 275px;

            &:before {
                top: -855px;
            }
            
            &:after {
                left: calc(50% - 40px);
                top: 210px;
            }
        }

        &__dropdown {
            &--select {
                width: 300px;
                padding: 15px 20px;
            }

            & svg {
                right: 20px;
            }
        }
    }
}
