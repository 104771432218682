.breadcrumb {

    ul  {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 50px;
        padding: 10px 0;
    }

    & svg {
        width: 20px;
        height: 20px;
        fill: $color-text;
        transform: rotate(-90deg);
        margin: 0 5px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;
        padding: 10px 0px;
        line-height: 1.25rem;

        a {
            color: $color-text;
            transition: all $duration ease-in-out;
        }

        // "Vous êtes ici" style
        &:first-child {
            padding-left: 0;
        }

        &:hover, &:focus {
            a {
                color: $color-gray;
            }
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 30px;
        }

        li {
            min-height: 30px;
            padding: 5px 0;
        }
    }

}
