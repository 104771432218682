.cover {
    position: relative;
    z-index: 0;
    background: $color-bg--neutral;

    &:before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 200px;
        right: calc(50% + 365px);
        height: 345px;
        width: 345px;
        background-image: url($urlShort + '/home/bg/fleur_border.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        pointer-events: none;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 155px;
        bottom: -155px;
        right: 0;
        left: 0;
        background: url('/wp-content/themes/cc-ploermel-communaute/assets/src/images/sprite-images/css/courbe_intro_css.svg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        pointer-events: none;
    }

    .shape-container {
        position: relative;
        left: calc(50% - (2000px/2));
        width: 2000px;
        height: 550px;

        &__image {
            display: block;
            width: 2000px;
            height: 550;
        }
    }

    &__container {
        display: inherit;
        position: relative;
        width: 100%;
        height: 100%;

        &--img {
            position: relative;
            clip-path: url(#topPostPath);
            top: 0;
            height: 550px;

            & rect {
                position: absolute;
                z-index: 1;
                width: 2000px;
                height: 550px;
                left: calc(50% - (2000px/2));
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                // background: linear-gradient(180deg, rgba($color-dark, 0.65) 0%, rgba(5, 8, 50, 0.045) 100%);
            }
        }
    }

    & svg.topPostMask {
        position: relative;
        width: 2000px;
        height: 550px;
        left: calc(50% - 1000px);
    }

    &__image-wrapper {
        display: flex;
        position: relative;
        z-index: 0;
        justify-content: center;
        height: 550px;
        clip-path: url(#topPostPath);

        img {
            width: 100%;
            height: 100%;
            max-width: 2000px;
            object-fit: cover;
            object-position: top;
        }

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background: linear-gradient(180deg, rgba(5, 8, 50, 0.65) 0%, rgba(5, 8, 50, 0.045) 100%);
        }
    }

    &__informations {
        background: $color-bg--neutral;
        padding: 0 0 40px;
        margin-bottom: 100px;
    }

    &__title {
        color: $color-main;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 10px 0 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text;
    }
}

.masked {
    &__image-wrapper {
        display: none;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {

        &:before {
            right: calc(50% + 230px);
            top: 225px;
        }

        &__image-wrapper {
            height: 450px;

            img {
                max-width: 1636px;
            }
        }

        .shape-container {
            left: calc(50% - (1636px/2));
            width: 1636px;
            height: 450px;
            
            &__image {
                width: 1636px;
                height: 450px;
            }
        }

        &__container {
            &--img {
                height: 450px;

                & rect {
                    left: calc(50% - (1636px/2));
                }
            }
        }

        & svg.topPostMask {
            left: calc(50% - (1636px/2));
            width: 1636px;
            height: 450px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {

        &:before {
            top: 175px;
            right: calc(50% + 200px);
            width: 300px;
            height: 300px;
        }

        &__image-wrapper {
            height: 370px;

            img {
                max-width: 1345px;
            }
        }

        .shape-container {
            left: calc(50% - (1345px/2));
            width: 1345px;
            height: 370px;
            
            &__image {
                width: 1345px;
                height: 370px;
            }
        }

        &__container {
            &--img {
                height: 370px;

                & rect {
                    left: calc(50% - (1345px/2));
                }
            }
        }

        & svg.topPostMask {
            left: calc(50% - (1345px/2));
            width: 1345px;
            height: 370px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .cover {  
        
        &:before {
            top: 235px;
            right: calc(50% + 130px);
            width: 200px;
            height: 200px;
        }
        
        &__image-wrapper {
            height: 300px;

            img {
                max-width: 1090px;
            }
        }

        .shape-container {
            left: calc(50% - (1090px/2));
            width: 1090px;
            height: 300px;
            
            &__image {
                width: 1090px;
                height: 300px;
            }
        }

        &__container {
            &--img {
                height: 300px;

                & rect {
                    left: calc(50% - (1090px/2));
                }
            }
        }

        & svg.topPostMask {
            left: calc(50% - (1090px/2));
            width: 1090px;
            height: 300px;
        }
    }
}
