.consultation {
    &__infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 30px;
    }

    &__vote {
        column-gap: 7.5px;
    }

    &__details {
        display: flex;
        flex-wrap: wrap;
        column-gap: 60px;
        row-gap: 20px;
    }

    &-detail {
        display: flex;
        align-items: center;
        column-gap: 10px;

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__icon {
            width: 40px;
            height: 40px;
            fill: $color-gray;
        }

        &__label {
            line-height: 1.15em;
            font-weight: 700;
            text-transform: uppercase;
        }

        &__value {

        }
    }


    &-survey {
        display: flex;
        flex-direction: column;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: none;
            border-radius: 0;
        }

        &__title {
            font-weight: 700;
            padding: 0;

            span {
                color: $color-flexible-alert;
            }
        }

        &__text {
            font-style: italic;
            color: $color-gray;
        }

        &__items {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 20px;
        }

        &__item {
            display: flex;
            align-items: center;
            column-gap: 7.5px;

            input {

            }

            label {
                font-weight: 400;
                margin-top: 2px;
            }
        }

        &__button {
            margin: 0 0 0 auto;
        }
    }
}

.message {
    &-voting,
    &-held {
        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

.acf-fields-cgu {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-top: 30px;

    label {
        font-weight: 400;
        margin-top: 0;

        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

.single-consultations .breadcrumb ul li:not(:first-child,:nth-child(2)){
    pointer-events: none;
}
