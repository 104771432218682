body.home.js-active-navmainburger {
    header {
        & .flash__section {
            top: -80px;
        }
        
        .headercontent--flashinfo {
            height: 0;
        }
    }
}

.home header {

    .headercontent--flashinfo {
        height: 80px;
    }

    .flash {

        &__section {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 80px;
            top: 0;
            z-index: 20;
            background-color: $color-main;
            transition: all $duration ease-in-out;
        }

        &__container {
            display: flex;
            align-items: center;
            height: inherit;

            .swiper-container {
                width: 800px;
                margin-left: unset;
            }
        }

        &__title {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1.375rem;
            line-height: 1.563rem;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;
            text-transform: uppercase;
            color: $color-white;
            margin-right: 90px;

            svg {
                width: 15px;
                height: 15px;
                margin-right: 10px;
                fill: $color-gray;
            }

            &:before {
                content: "";
                position: absolute;
                width: 40px;
                height: 1px;
                top: 50%;
                right: -65px;
                background-color: $color-white;
                transform: translateY(-50%);
            }
        }

        &__content {
            &__title {
                font-size: $font-size--text;
                line-height: 1.25rem;
                font-weight: $font-weight-bold;
                color: $color-white;
            }
        }

        &__navigation {
            position: relative;
            width: 90px;
            height: 40px;

            .swiper-button {
                width: 40px;
                height: 40px;
                border: 0;
                padding: 0;
                margin: 0;
                top: 0;
                background-color: $color-gray;
                transition: background-color $duration ease-in-out;

                &:before, &:after {
                    content: none;
                }

                &-prev {
                    left: 0;
                }

                &-next {
                    right: 0;
                }

                & svg {
                    fill: $color-text;
                    width: 40px;
                    height: 40px;
                }

                &:hover, &:focus {
                    background-color: $color-second;
                }
            }
        }

        // & ~ .header-top {
        //     .nav-main--burger.js-active-menu {
        //         margin-top: -50px;
        //     }
        // }
    }

    & .identity {
        &__title {

            &--logo {
                position: absolute;
                width: 270px;
                height: 165px;
                top: 85px;
                left: 0;
                right: 0;
                opacity: 1;
                margin: 0 auto;
                transition: all $duration ease-in-out;
            }

            &--logo-fixed {
                display: inline-block;
                position: absolute;
                width: 387px;
                top: -100px;
                left: 0;
                right: 0;
                opacity: 0;
                margin: 0 auto;
                transition: all $duration ease-in-out;
            }
        }
    }

    &.header--fixed {
        & .identity {
            &__title {
                &--logo {
                    top: -200px;
                    opacity: 0;
                }
    
                &--logo-fixed {
                    top: 0;
                    opacity: 1;
                }
            }
        }

        & .header {
            &__social--list {
                margin: 20px 0;
            }

            &__container {
                &:before {
                    top: 40px;
                }
            }
        }

        & .tools {
            &__content {
                margin: 20px 0;
            }
        }

        & .nav-main--burger .nav-main-buttons__menu {
            margin: 20px 0;
        }
    }
}

header {
    position: relative;
    height: auto;
    transition: all $duration ease-in-out;

    
    .headercontent {
        transition: all $duration ease-in-out;
    }

    .flash {

        &__section {
            display: none;
        }
    }

    .header-top {
        position: absolute;
        bottom: -80px;
        width: 100%;
        height: 80px;
        z-index: 15;
        background-color: $color-bg--transparent;
        backdrop-filter: blur(0px);
        transition: all $duration ease-in-out;
    }

    &.header--fixed {

        .header-top {
            position: fixed;
            top: 0;
            bottom: unset;
            height: 80px;
            background-color: rgba($color-main, .9);
            backdrop-filter: blur(5px);
        }

        .identity__title--logo-fixed {
            height: 80px;
        }
    }

    .header {

        &__container {
            display: flex;
            position: relative;
            
            &:before {
                content: "";
                position: absolute;
                z-index: 20;
                height: 1px;
                width: calc(50vw - 600px);
                right: calc(50% + 600px);
                top: 40px;
                background-color: $color-white;
            }
        }

        &__social {
            &--list {    
                position: relative;
                z-index: 20;
                display: flex;
                align-items: center;
                padding: 0;
                margin: 20px 0;
                height: 40px;
                transition: all $duration ease-in-out;
            }
    
            &--link {
                display: inline-block;
                width: 40px;
                height: 40px;
                margin: 0 15px 0 0;
                background-color: $color-bg--transparent;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;

                & svg {
                    width: 40px;
                    height: 40px;
                    fill: $color-white;
                }

                &:last-child {
                    margin: 0;
                }

                &:hover, &:focus {
                    background-color: rgba(239, 239, 245, 0.3);
                }
            }
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 80px;
    color: $color-text;
    transition: color $duration ease-in-out;

    &:hover {
        color: $color-second;
    }

    &__title {
        font-size: 0;
        line-height: 0;

        &--logo-nothome {
            display: inline-block;
            position: absolute;
            height: 80px;
            top: 0;
            left: 0;
            right: 0;
            opacity: 1;
            margin: 0 auto;
            transition: all $duration ease-in-out;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 0;
        margin: 20px 0;
        background: $color-bg--transparent;
        pointer-events: auto;
        transition: all $duration ease-in-out;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        border: 1px solid $color-white;
        background-color: $color-bg--transparent;
        border-radius: $border-radius--round;
        cursor: pointer;

        svg {
            fill: $color-white;
            transition: all $duration ease-in-out;
        }

        &:first-child {
            margin: 0 15px 0 0;
        }

        &:hover, &:focus {
            border-color: $color-second;
            background-color: $color-second;

            svg {
                fill: $color-text;
            }
        }

        &--accessibility {
            svg {
                width: 40px;
                height: 40px;
            }
        }

        &--search {
            svg {
                width: 40px;
                height: 40px;
            }

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        header {

            .identity {
                &__title {
                    &--logo {
                        width: 220px;
                        height: 135px;
                        top: 95px;
                    }
                }
            }

            &__social {
                &--list {
                    margin: 40px 0 15px;
                }
            }

            & .tools {
                &__content {
                    margin: 40px 0 15px;
                }
            }

            & .nav-main--burger .nav-main-buttons__menu {
                margin: 40px 0 15px;
                right: 10px;
            }

            & .nav-main--burger.js-active-menu .nav-main-buttons__menu {
                right: calc(50% - 470px);
            }

            .flash {
                &__title {
                    margin-right: 70px;

                    &:before {
                        width: 30px;
                        right: -50px;
                    }
                }

                &__container {
                    .swiper-container {
                        width: 600px;
                    }
                }
            }
        }
    }

    header {
        .header {
            &__container {
                &:before {
                    right: calc(50% + 485px);
                    width: calc(50vw - 485px);
                }
            }
        }
    }

    .identity__title--logo-fixed {
        width: 345px;
    }

    .identity__title--logo-nothome {
        width: 345px;
        top: 5px;
    }
}


// 960
@media screen and (max-width: $medium) {
    
    .home {
        header {
            .flash {
                &__container {
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    .swiper-container {
                        width: 470px;
                        height: 25px;
                        margin-right: 80px;
                    }
                }

                &__title {
                    margin-bottom: 5px;
                }

                &__navigation {
                    width: 70px;
                    height: 30px;

                    .swiper-button {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            
            & .nav-main--burger .nav-main-buttons__menu {
                margin: 25px 0 15px;
                right: 10px;
            }
            
            .identity__title--logo-fixed {
                display: none;
            }

            &.header--fixed {
                .tools {
                    &__content {
                        margin: 0;
                    }
                }
            }
        }
    }
    header {

        .identity__title--logo-nothome {
            width: 270px;
            height: 80px;
        }

        &.header .header__container {
            &:before {
                z-index: 10;
            }
        }

        &.header .header__social--list {
            z-index: 10;
        }

        .nav-main {
            &--burger {
                .nav-main-buttons {
                    width: 30px;
                    padding: 0;

                    &__menu {
                        right: 0!important;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        column-gap: 0;
                        height: 30px;
                        width: 30px;
                        margin-top: 25px;
                        border: 1px solid  $color-white;
                        border-radius: $border-radius--round;

                        &__icon {
                            width: 15px;
                            height: 1px;
                            min-width: 15px;

                            &:before {
                                top: -4px;
                                width: 10px;
                            }

                            &:after {
                                top: 4px;
                            }
                        }

                        &:before {
                            content: "";
                        }
                    }
                }

                &.js-active-menu {
                    .nav-main-buttons {
                        &__menu {
                            &:before {
                                content: ""!important;
                            }
                        }
                    }
                }
            }
        }

        &.header .header {
            &__container {
                &:before {
                    top: 45px;
                    right: calc(50% + 325px);
                    width: calc(50vw - 325px);
                }
            }

            &__social {

                &--list {
                    margin: 25px 0 15px;
                }

                &--link {
                    margin: 0 5px 0 0;
                }
            }
        }

        &.header {

            & .tools__content {
                height: 80px!important;
                margin: 0;
            }
            
            & .tools--burger {
                margin-right: 15px;

                & .tools__item {
                    width: 30px;
                    height: 30px;

                    & svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .identity__title--logo-fixed {
            width: 270px;
        }
    
        .identity__title--logo-nothome {
            top: 0;
            width: 270px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    body.home.js-active-navmainburger {
        header {

            & .flash__section {
                top: -120px;
            }

            .headercontent--flashinfo {
                height: 0;
            }
        }
    }

    .home {
        header {

            .headercontent--flashinfo {
                height: 120px;
            }

            .flash {

                &__section {
                    height: 120px;
                }

                &__container {

                    .swiper-container {
                        width: 240px;
                        height: 40px;
                        margin-right: 30px;
                    }
                }

                &__title {
                    margin-bottom: 5px;
                }

                &__navigation {
                    display: flex;
                    flex-direction: column;
                    width: 30px;
                    height: 70px;

                    .swiper-button {
                        &-prev {
                            top: 0;
                        }

                        &-next {
                            top: unset;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    header {
        .header {
            &__container {
                max-width: 85%;
                
                &:before {
                    content: none;
                }
            }

            &__social--list {
                display: none;
            }

            .nav-main--burger {
                margin-right: 90px;
            }
        }

        .identity__title--logo-nothome {
            top: 65px;
            height: auto;
        }

        .nav-main--buger {
            .nav-main-buttons {
                margin: 0!important;
                min-width: 30px!important;
            }
        }
    }
}