.nav-main--burger {
    z-index: inherit;
    height: 0;
    background-color: $color-main;

    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            position: absolute;
            top: 0;
            right: 15px;
            z-index: 11;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 15px;
            margin: 20px 0;
            padding: 0 0 0 15px;
            width: 115px;
            height: 40px;
            background-color: $color-bg--transparent;
            border: 0;
            border-radius: 0;
            transition: unset;
            cursor: pointer;

            &::before {
                content: "Menu";
                font-size: 1.25rem;
                line-height: 1.563rem;
                font-weight: $font-weight-semibold;
                font-family: $font-family--heading;
                letter-spacing: 0.05em;
                color: $color-white;
                text-transform: uppercase;
            }

            // Burger menu Button icon
            &__icon {
                position: relative;
                top: -1px;
                display: block;
                height: 2px;
                width: 22px;
                min-width: 20px;
                background-color: $color-white;
                transition: unset;

                &::before, 
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 100%;
                    background-color: $color-white;
                    transition: unset;
                }

                &::before {
                    top: -7px;
                    right: 0;
                    width: 16px;
                }

                &::after {
                    top: 7px;
                    width: 100%;
                } 
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        position: fixed;
        top: -100vh;
        left: 0;
        right: 0;
        z-index: 10;
        display: block;
        max-width: inherit;
        width: 100%;
        height: 100vh;
        background: linear-gradient(90deg, $color-main calc(50% - 235px), $color-white  calc(50% - 235px));
        padding: 0;
        transition: all $duration ease-in-out;

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: calc(50% - 235px);
            width: 100px;
            height: 100%;
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url($urlShort + "home/bg/courbe_sousmenu.svg");
            transition: all $duration ease-in-out;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            width: calc(50% - 235px);
            height: 100%;
            background-color: $color-main;
            transition: all $duration ease-in-out;
        }

        .menu-ar {
            position: absolute;
            bottom: 100px;
            right: calc(50% - 585px);
            display: inline-block;
            width: 218px;
            height: auto;

            &__label {
                display: inline-block;
                font-size: 1.875rem;
                line-height: 2.188rem;
                font-family: $font-family--heading;
                font-weight: $font-weight-bold;
                margin-bottom: 30px;
            }

            &__link {
                display: block;
                position: relative;
                padding: 15px 0 15px 15px;
                border-bottom: 1px solid $color-main;
                transition: all $duration ease-in-out;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto 0;
                    width: 6px;
                    height: 6px;
                    background-color: $color-main;
                    border-radius: $border-radius--round;
                    transition: all $duration ease-in-out;
                }

                &:hover, &:focus {
                    padding-left: 30px;

                    &::before {
                        background-color: $color-second;
                    }
                }
            }
        }
    }

    // Section menu (for overflow)
    &__section {
        overflow-y: auto;
        height: 100%;
        padding: 0;
    }

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    // if Burger menu open
    &.js-active-menu {

        // Burger menu Button
        .nav-main-buttons__menu {   
            width: fit-content;
            position: fixed;
            top: 0;
            right: calc(50% - 585px);
            column-gap: 10px;
            
            &::before {
                content: "Fermer";
                color: $color-main;
            }

            // Burger menu Button icon
            &__icon {
                height: 25px;
                width: 25px;
                background: url($urlShort + 'base-icons/ic-nav-close.svg');

                &::before, &:after {
                    content: none;
                }
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
        }

        // Main menu
        .menu {
            display: flex;
            transform: scaleY(1);
        }

    }

    &.js-active-submenu {
        
        // Popup menu
        .nav-main--burger__popup {

            &:before {
                left: calc(50% + 200px);
            }

            &:after {
                width: calc(50% + 200px);
            }

            .menu {
                &:after {
                    opacity: .1;
                }
            }
        }
    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 60px;
        height: auto;
        min-height: 100vh;
        width: calc(50% - 235px);
        padding: 170px 0 40px;
        transition: all $duration ease-in-out;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: $color-white;
            opacity: 0;
            transition: all $duration ease-in-out;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &.menu-item-has-children {

                &:focus-within, 
                &:hover {
                    .submenu {
                        display: none;
                    }
                }

                &.js-active-submenu {

                    .menu__link {
                        padding: 0 0 0 30px;

                        &:before {
                            left: 0;
                            opacity: 1;
                        }
                    }

                    .submenu {
                        display: block;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            max-width: inherit;
            width: 100%;
            height: auto;
            font-size: 2.188rem;
            line-height: 2.5rem;
            padding: 0 30px 0 0;
            color: $color-white;

            &::before, &::after {
                content: none;
            }

            &:hover, &:focus {
                padding: 0 0 0 30px;
                background-color: $color-bg--transparent;
                color: $color-white;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -20px;
                bottom: 0;
                width: 20px;
                height: 20px;
                opacity: 0;
                margin: auto 0;
                background: url($urlShort + 'pictos/fleur.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                transition: all $duration ease-in-out;
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        position: absolute;
        top: 0;
        right: inherit;
        left: 100%;
        z-index: inherit;
        width: 435px;
        padding: 170px 60px 0 70px;
        background-color: $color-bg--transparent;
        box-shadow: none;

        &__nav {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 20px 30px;
            max-width: inherit;
            max-height: 80vh;
            padding: 0;

            &:before {
                content: "";
                position: absolute;
                z-index: 0;
                width: 400px;
                height: 400px;
                top: 415px;
                left: 66px;
                background: url($urlShort + '/home/bg/fleur_degrade.svg');
            }
        }

        &__item {
            width: auto;
            margin: 0;         
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: auto;
            height: auto;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 0;
            border: 0;
            color: $color-white;
            font-size: 1.125rem;
            font-weight: $font-weight-medium;

            &::before,  &::after {
                content: none;
            }

            &:hover, &:focus {
                color: $color-second;
            }
        }
    }

}

// Button accessibility
button.avoid-main-content {
    position: absolute;
    bottom: 40px;
    right: 40px;
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For Responsive (no scroll on body when menu is open)
body.js-active-navmainburger {
    overflow: hidden;
}


//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================
// Tools
.header .tools--burger {
    top: auto;
    margin-left: auto;
    margin-right: 130px;
}

// Identity
.identity.identity--burger {
    z-index: 9;
}

body.js-active-navmainburger {
    .identity__title {
        margin-top: 30px;
        color: $color-white;
        transition: margin-top $duration ease-in-out, color $duration ease-in-out;
    }

    .quick-access {
        right: -960px;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {

        // Main menu
        .menu {
            width: 30%;
            margin-top: 150px;
            padding: 0 30px 0 0;
            gap: 45px;

            &__link {
                font-size: 1.875rem;
            }
        }

        // Sub menu
        .submenu {
            width: 380px;
            padding: 0 30px;

            &__link {
                font-size: 1.125rem;
            }
        }

        &__popup {
            background: linear-gradient(90deg,#242756 calc(50% - 125px),#fff calc(50% - 125px));
            
            .menu-ar {
                right: calc(50% - 470px);
            }

            &:before {
                left: calc(50% - 125px);
            }

            &:after {
                width: calc(50% - 125px);
            }
        }

        &.js-active-menu {
            .nav-main-buttons__menu {
                right: calc(50% - 470px);
            }
        }

        &.js-active-submenu {
            .nav-main--burger__popup {
                &:before {
                    left: calc(50% + 175px);
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    
    // Header -- Identity
    // .identity.identity--burger {
    //     position: inherit;
    //     z-index: inherit;
    //     max-width: 100%;
    //     height: auto;
    // }
    body.js-active-navmainburger .identity__title {
        margin-top: 0;
        color: $color-text;
    }

    // Menu
    .nav-main--burger {

        // if Burger menu open
        &.js-active-menu {

            // Burger menu Button
            .nav-main-buttons__menu { 
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: inherit;
                column-gap: 0;
                height: 30px;
                width: 30px;
                margin: 25px 0 0;
                padding: 0;
                border-radius: $border-radius--round;
                border: 1px solid $color-white;
        
                &::after {
                    display: none;
                }

                &__icon {
                    height: 15px;
                    height: 15px;
                    width: 15px;
                    background: url($urlShort + 'base-icons/ic-nav-close-white.svg');

                    &:before, &:after {
                        left: 2px;
                        background: $color-white;
                    }

                    &:before {
                        top: -2px;
                    }

                    &:after {
                        top: 2px;
                    }
                }
            }

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
            }

            // And If submenu open
            .nav-main-buttons.js-active-submenu {
                .nav-main-buttons__submenu {
                    right: 600px;
                }

                & ~ .nav-main--burger__popup .menu__nav__list {
                    z-index: 1;
                }
            }
        }

        // If submenu open
        &.js-active-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            z-index: 11;

            // Burger menu Button
            &__menu {
                position: relative;
                width: fit-content;
                top: auto;
                right: auto;
                padding: 20px 0;
                transition: none;
                z-index: 10;
                opacity: 1;
                transition: all $duration ease-in-out;

                // Burger menu Button icon
                &__icon, 
                &__icon::before, 
                &__icon::after {
                    transition: none;
                }   
            }

            // Burger menu responsive - Close submenu
            &__submenu {
                position: absolute;
                top: 0;
                right: 200vw;
                z-index: 11;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                margin: 25px 0 0;
                width: 30px;
                height: 30px;
                padding: 0;
                background-color: $color-bg--transparent;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 30px;
                    height: 30px;
                    fill: $color-white;
                }
            }
        }

        // Popup menu
        &__popup {
            // top: 0;
            right: inherit;
            // left: -100vw;
            padding: 0;
            overflow: hidden;
            background: $color-white;

            &::before {
                content: none;
            }

            &:after {
                content: none;
            }

            .menu-ar {
                position: initial;
                width: auto;
                bottom: unset;
                right: 0;
                left: 0;
                top: 665px;
                margin: 80px calc(50% - 320px) 100px;

                &__list {
                    height: 120px;
                    flex-wrap: wrap;
                    display: flex;
                    gap: 0 100px;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                }

                &__item {
                    width: 220px;
                }
            }
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
            flex-direction: column;
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            width: 100vw;
            height: auto;
            margin: 0 auto;
            padding: 80px calc(50% - 320px) 60px;
            background-color: $color-main;
            min-height: unset;
            overflow: unset;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 100px;
                background: url($urlShort + 'css/courbe_sousmenu_css.svg');
                top: unset;
                bottom: -100px;
                margin: 0 auto;
            }

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: all $duration;
                    }

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-active-submenu {
                        .submenu {   
                            left: 0;
                            display: flex;
                            transition: all $duration ease-in-out;
                        }
                    }
                }

                &:after {
                    display: none;
                }
            }

            &__link {
                padding: 0 0 0 30px;

                &:before {
                    opacity: 1;
                    left: 0;
                    filter: grayscale(1);
                }

                &:hover, &:focus {
                    filter: grayscale(0)
                }
            }
        }

        // Sub menu
        .submenu {
            top: 0;
            right: inherit;
            left: -100vw;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 100vw;
            height: 100vh;
            padding: 80px 0 0;
            background-color: $color-main;
            transition: all $duration ease-in-out;

            &::after {
                display: none;
            }
    
            &__nav {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-wrap: nowrap;
                max-width: 100%;
                width: 100%;
                max-height: inherit;
                height: calc(100vh - 100px);
                overflow-y: auto;
                padding: 0 calc(50% - 320px) 100px;
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;

                &::before {
                    display: none;
                }
            }
        }
    }

    .nav-main--burger.js-active-submenu .nav-main--burger__popup:before {
        left: 0!important;
    }

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .menu {
                padding-top: 80px;
            }

            .submenu {
                padding-top: 80px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    // .home {
    //     header {
    //         .nav-main {
    //             &--burger {
    //                 .nav-main-buttons__menu {
    //                     margin: 40px 40px 15px 0!important;
    //                 }
    //             }
    //         }
    //     }
    // }

    .header {
        .tools {
            &--burger {
                margin-left: calc(50% - 60px);
                margin-right: 170px;
            }
        }
    }

    .nav-main {
        &--burger {
            margin-right: 90px;
            transition: all $duration ease-in-out;

            .nav-main-buttons {
                &__menu {
                    min-width: 30px;
                }
            }

            &.js-active-menu {
                margin-right: 0;

                .nav-main-buttons {
                    position: initial;

                    &__menu {
                        right: 0!important;
                        z-index: 50;
                    }
                }

                // And If submenu open
                .nav-main-buttons.js-active-submenu {
                    .nav-main-buttons__submenu {
                        right: unset;
                        left: 0;
                    }
                }

                .menu {
                    z-index: 1;
                }
            }

            &__popup {
                background: $color-white;

                &:before {
                    content: none;
                }

                .menu-ar {
                    position: initial;
                    margin: 80px 7% 100px;

                    &__list {
                        flex-wrap: unset;
                        gap: unset;
                        height: unset;
                    }
                }
            }

            &__container {
                flex-direction: column;
                overflow-x: hidden;
            }

            .menu {
                padding: 100px 7% 60px;
                background-color: $color-main;
                min-height: unset;
                overflow: unset;

                &::before {
                    content: "";
                    position: absolute;
                    width: 1200px;
                    height: 100px;
                    background: url($urlShort + 'css/courbe_sousmenu_css.svg');
                    top: unset;
                    left: calc(50% - 600px);
                    bottom: -100px;
                    margin: 0 auto;
                }
            }

            .submenu {
                position: fixed;

                &__nav {
                    padding: 50px 7% 100px;
                    overflow-x: hidden;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main--burger {
            .submenu {
                padding-top: 45px;
            }
        }
    }

}
